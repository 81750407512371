:root {
  --m3-display-large-font-family: "Roboto", Helvetica;
  --m3-display-large-font-size: 57px;
  --m3-display-large-font-style: normal;
  --m3-display-large-font-weight: 400;
  --m3-display-large-letter-spacing: -0.25px;
  --m3-display-large-line-height: 64px;
  --m3-display-medium-font-family: "Roboto", Helvetica;
  --m3-display-medium-font-size: 45px;
  --m3-display-medium-font-style: normal;
  --m3-display-medium-font-weight: 400;
  --m3-display-medium-letter-spacing: 0px;
  --m3-display-medium-line-height: 52px;
  --m3-display-small-font-family: "Roboto", Helvetica;
  --m3-display-small-font-size: 36px;
  --m3-display-small-font-style: normal;
  --m3-display-small-font-weight: 400;
  --m3-display-small-letter-spacing: 0px;
  --m3-display-small-line-height: 44px;
  --m3-label-large-prominent-font-family: "Roboto", Helvetica;
  --m3-label-large-prominent-font-size: 14px;
  --m3-label-large-prominent-font-style: normal;
  --m3-label-large-prominent-font-weight: 600;
  --m3-label-large-prominent-letter-spacing: 0.10000000149011612px;
  --m3-label-large-prominent-line-height: 20px;
  --m3-label-medium-font-family: "Roboto", Helvetica;
  --m3-label-medium-font-size: 12px;
  --m3-label-medium-font-style: normal;
  --m3-label-medium-font-weight: 500;
  --m3-label-medium-letter-spacing: 0.5px;
  --m3-label-medium-line-height: 16px;
  --m3-label-small-font-family: "Roboto", Helvetica;
  --m3-label-small-font-size: 11px;
  --m3-label-small-font-style: normal;
  --m3-label-small-font-weight: 500;
  --m3-label-small-letter-spacing: 0.5px;
  --m3-label-small-line-height: 16px;
}
